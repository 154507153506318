import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/telegram/telegram-route-ticket",
    name: "TelegramRouteTicket",
    component: () => import("../views/telegram/telegram-route-ticket.vue"),
  },
  {
    path: "/telegram/telegram-list",
    name: "TelegramList",
    component: () => import("../views/telegram/telegram-list.vue"),
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: () => import("../views/welcome.vue"),
  },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/home.vue"),
    children: [
      {
        path: "/",
        name: "Main",
        component: () => import("../views/main.vue"),
      },
      {
        path: "/passports",
        name: "Passport",
        component: () => import("../views/passport/passport.vue"),
      },
      {
        path: "/clients",
        name: "Client",
        component: () => import("../views/client/client.vue"),
      },
      {
        path: "/messages",
        name: "Message",
        component: () => import("../views/message/message.vue"),
      },
      {
        path: "/lists",
        name: "List",
        component: () => import("../views/list/list.vue"),
      },
      {
        path: "/route-tickets",
        name: "RouteTicket",
        component: () => import("../views/route-ticket/route-ticket.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.path == "/welcome" ||
    to.path == "/telegram/telegram-list" ||
    to.path == "/telegram/telegram-route-ticket"
  ) {
    return next();
  }
  const token = localStorage.getItem("token");
  if (!token) return next("/welcome");
  next();
});

export default router;
