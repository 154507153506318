import Vue from "vue";

Vue.filter("money_humanize", (value, separator = " ") => {
  if (!value) return null;
  return parseFloat(value)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, separator);
});

Vue.filter("money", (value, separator = " ") => {
  if (!value) return null;
  return parseFloat(value)
    .toFixed(0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, separator);
});
