<template>
  <v-app class="font-mono text-[14px]">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({}),
};
</script>
