import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    overlay: false,
  },
  getters: {
    getOverlay: (state) => state.overlay,
  },
  actions: {},
  modules: {},
});
